import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { IBlogPostShort } from '../../app/models/content/IBlogPostShort';
import { useStore } from '../../app/stores/store';
//@ts-ignore
import Pagination from 'react-bootstrap-4-pagination';
import { PagingParams } from '../../app/models/pagination';
// import AdvertsBlock from '../adverts/AdvertBlock';
import SearchBox from '../home/SearchBox';
import { useMatomo } from '@datapunt/matomo-tracker-react';
// import BacklinkProductBlock from '../blocks/BacklinkProductBlock';
import TagSummaryBlock from '../blocks/TagSummaryBlock';

export default observer(function ListPosts () {

    const { enableLinkTracking, trackPageView, trackEvent } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { pagination, setPredicate } = blogStore;

    useEffect(() => {  
        blogStore.loadPosts();  

        trackPageView({
            documentTitle: 'Artikelübersicht'
        });
        
    }, [blogStore, trackPageView])

    function changePage(page: any) {
        // console.log(page);
        blogStore.setPagingParams(new PagingParams(page));
        blogStore.loadPosts();

        trackEvent({ 
            category: `Artikel Seite - ${page}`,
            action: 'Artikelübersicht' })
    }

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
    }

    function handleTagSelect(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchTag', search);
    }
    
    return (
        
        <Fragment>
            <Helmet>
                <title>Themenübersicht zu Artikeln der Händler und Lieferanten</title>      
                <meta name="description" content="Fachartikel zum Thema Händler und Lieferanten" />
                <meta name="keywords" content="lieferanten finden, produkte finden, dienstleistungen finden" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            
            <Row>
            <Col md={9}>
            <h1>Artikelübersicht</h1>
            <p className="lead">Aktuelle Fachartikel unserer Händler und Lieferanten</p>
            <hr />
            {/* <AdvertsBlock advertCount={1} advertType={5} /> */}

            {blogStore.posts.map((post: IBlogPostShort, i) => (
                <Card key={i} style={{marginBottom: '1em'}}>
                <Card.Body>
                    <Card.Title><Link to={`/artikel/${post.slug}`}>{post.title}</Link></Card.Title>
                    <hr />
                    <Card.Text>{post.abstract}</Card.Text>
                </Card.Body>
            </Card>
            ))}

            <Pagination
                  threeDots={true}
                  prevNext={true}
                  totalPages={pagination?.totalPages ? pagination?.totalPages : 1}
                  currentPage={pagination?.currentPage}
                  showMax={7}
                  onClick={(page: any) => changePage(page)}                  
                />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />
                {/* <BacklinkProductBlock /> */}
                <TagSummaryBlock updateSearch={handleTagSelect} />
                {/* <AdvertsBlock advertCount={1} advertType={6} /> */}
            </Col>
            </Row>

            </Fragment>
    )
})