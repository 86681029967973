import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    return (
        <Fragment>
            <Helmet>
                <title>Produkte und Dienstleistungen einfach finden</title>      
                <meta name="description" content="Plattform für Produkte und Dienstleistungen, Händler und Lieferanten einfach finden" />
                <meta name="keywords" content="lieferanten finden, produkte finden, dienstleistungen finden" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Portal für Produkte und Dienstleistungen</h1>
            <AdvertOwnBlock
                title='Händler und Lieferanten für Produkte und Dienstleistungen einfach finden'
                content='<p>Sie haben ein Gewerbe, einen Laden oder einen Online-Shop und benötigen bestimmte Produkte und Dienstleistungen, um Ihr Geschäft voranzubringen?</p><p>Dieses Portal listet Händler und Lieferanten und ermöglicht die gezielte Suche, damit Sie beide zueinander finden.</p>'
                image='/banners/lieferanten-auskunft-banner.png'
                imageTarget=''
                targets={[
                    // { linkText: 'Wellness', linkTarget: '/wellness' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <NewsletterSubscribe />

                {/* <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/51eZPcvuHUL.jpg'
                    text='Umprogrammierung deines Stoffwechsels-Dauerhafte Gewichtsreduktion'
                    link='https://www.amazon.de/dp/1706754930/ref=as_li_ss_tl?dchild=1&keywords=gewichtsreduktion&qid=1624019285&rnid=1703609031&s=books&sr=1-1&linkCode=ll1&tag=kayomo-21&linkId=ee2a050f3f65605541255e14bd409382&language=de_DE'
                    title='Stoffwechsel Dich schlank'
                /> */}

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}